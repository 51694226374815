import React from "react";
import { Container } from "react-bootstrap";
import "./AboutMe.scss";
import ThemedButton from "./ThemedButton";
import face3 from "../assets/face3.jpg";

interface IAboutMeProps {
  onBookClick: () => void;
}

const AboutMe = React.forwardRef<HTMLDivElement, IAboutMeProps>(
  (props: IAboutMeProps, ref) => {
    return (
      <div ref={ref} className="b-about-section">
        <Container>
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
            <div className="col">
              <div className="b-about-img-wrapper d-md-block">
                <img src={face3} alt="About me" />
              </div>
            </div>
            <div className="col">
              <div className="b-about-section-name">
                <h1>ABOUT ME</h1>

                <div className="b-about-img-wrapper b-about-img-wrapper2 d-block d-md-none">
                  <img src={face3} alt="About me" />
                </div>
                <p>
                  I am a certified <strong>Phi Shading</strong> Artist who is
                  passionate about creating custom and natural looking brows
                  that are unique to each client. I have mastered the art of
                  PhiShading and microblading through extensive training with
                  Phi Academy, one of the best academies in the world. I was
                  developing my skills in both North America and Europe.
                </p>
                <p>
                  In addition to my passion for art and beauty, I obtained my
                  master degree in Conservation and Restoration of Fine Arts at
                  the Academy of Fine Arts in Warsaw, Poland. A passion to art,
                  painting, drawing and sculpturing gave me an exceptional
                  understanding of color, design and style.
                </p>
                <div className="d-md-none text-center">
                  <ThemedButton
                    size="sm"
                    theme="darker"
                    trackingId="book_aboutme"
                    text="BOOK AN APPOINTMENT WITH ME"
                    onClick={props.onBookClick}
                  ></ThemedButton>
                </div>
                <div className="d-none d-md-block">
                  <ThemedButton
                    size="sm"
                    theme="darker"
                    trackingId="book_aboutme"
                    text="BOOK AN APPOINTMENT WITH ME"
                    onClick={props.onBookClick}
                  ></ThemedButton>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
);

export default AboutMe;
