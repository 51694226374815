import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./InstaCard.scss";
import { Instagram } from "react-bootstrap-icons";
import ReactGA from "react-ga";

interface IInstaCardProps {
  imgSrc?: string;
  altImgSrc?: string;
  text?: string;
  onClick?: () => void;
  trackingId: string;
}

function InstaCard(props: IInstaCardProps) {
  var openInstagram = useCallback(() => {
    ReactGA.event({
      action: "instacard-" + props.trackingId,
      category: "onClick",
    });
    window.open("https://instagram.com/browsandyou.wa", "_blank");
  }, []);

  var [isAltVisible, setIsAltVisible] = useState(false);
  var [url, setUrl] = useState<string | undefined>(undefined);
  var [altUrl, setAltUrl] = useState<string | undefined>(undefined);

  var swapImg = useCallback(() => {
    setIsAltVisible(!isAltVisible && props.altImgSrc != undefined);
  }, [isAltVisible, setIsAltVisible, props.altImgSrc]);

  useEffect(() => {
    const timer = setInterval(() => {
      swapImg();
    }, 2000 + Math.random() * 3000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    setUrl(props.imgSrc);
    setAltUrl(props.altImgSrc);
  }, [setUrl, setAltUrl]);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip className="b-insta-tooltip">
          <Instagram />
          <span className="m-2">Click to view on Instagram</span>
        </Tooltip>
      }
    >
      <div className="b-insta-card" onClick={openInstagram}>
        <div className="b-insta-card-img-wrapper">
          {!isAltVisible && (
            <img
              className="b-insta-card-img"
              src={url}
              loading={"lazy"}
              alt={props.text}
            />
          )}

          <img
            className="b-insta-card-img"
            src={altUrl}
            loading={"lazy"}
            alt={props.text}
          />
        </div>
      </div>
    </OverlayTrigger>
  );
}

export default InstaCard;
