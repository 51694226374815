import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";
import "./Section.scss";

interface ISectionProps {
  name: string;
  children?: ReactNode;
}

const Section = React.forwardRef<HTMLDivElement, ISectionProps>(
  (props: ISectionProps, ref) => {
    var sectionContent = (
      <>
        <div ref={ref} className="b-section">
          <div className="b-section-name">
            <h1>{props.name}</h1>
          </div>
          {props.children}
        </div>
      </>
    );

    return <Container>{sectionContent}</Container>;
  }
);

export default Section;
