import { useEffect } from "react";
import Faq from "../components/Faq";
import Section from "../components/Section";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

interface IFaqPageProps {
  onBookingClick: () => void;
}

function FaqPage(props: IFaqPageProps) {
  var location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <div className="App">
      <Section name="FAQ">
        <Faq />
      </Section>
    </div>
  );
}

export default FaqPage;
