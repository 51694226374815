import React from "react";
import "./Pricing.scss";
import sevid from "../assets/sevid.png";
import ThemedButton from "./ThemedButton";

interface IPricingDetails {
  price?: string;
  name?: string;
  descriptionLine1?: string;
  descriptionLine2?: string;
  descriptionLine3?: string;
  descriptionLine4?: string;
  descriptionLine5?: string;
}

function Pricing() {
  var prices: IPricingDetails[] = [
    {
      price: "Free",
      name: "Consultation",
      descriptionLine1: "Online or in-person",
    },
    {
      price: "$400",
      name: "Microblading",
      descriptionLine1: "Free touch-up appointment between 4-8 weeks included",
    },
    {
      price: "$450",
      name: "Phi Shading",
      descriptionLine1: "Phi Shading Combination Brows",
      descriptionLine2: "Free touch-up appointment between 4-8 weeks included",
    },
    {
      price: "",
      name: "Color Boost",
      descriptionLine1: "Additional touch-up appointment after:",
      descriptionLine2: "3-6 months · $150",
      descriptionLine3: "7-12 months · $200",
      descriptionLine4: "13-18 months · $250",
      descriptionLine5: "19-24 months · $300",
    },
  ];
  return (
    <div className="row section-row justify-content-center">
      <div className="col-md-8">Prices available at www.sevidbeauty.com</div>
      <img className="col-md-8" style={{ maxWidth: "500px" }} src={sevid} />
      <a href="https://sevidbeauty.com">
        <ThemedButton
          theme="black"
          size="lg"
          trackingId="book-btn-promo-no-book"
        >
          Click here to visit sevidbeauty.com
        </ThemedButton>
      </a>
    </div>
  );
}

export default Pricing;
