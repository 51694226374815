import React, { ReactNode } from "react";
import "./InstaCards.scss";

interface IInstaCardsProps {
  children?: ReactNode;
}

function InstaCards(props: IInstaCardsProps) {
  return <div className="b-insta-cards">{props.children}</div>;
}

export default InstaCards;
