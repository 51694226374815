import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo_react.svg";
import ThemedButton from "./ThemedButton";
import "./TopNavigation.scss";

interface ITopNavigationProps {
  onBookClick: () => void;
}

function TopNavigation(props: ITopNavigationProps) {
  return (
    <Navbar bg="light" variant="dark" expand="lg" id="b-navbar-dark">
      <Container>
        <Navbar.Brand className="b-nav-brand">
          <NavLink
            className={({ isActive }) => (isActive ? "b-nav-link-active" : "")}
            to="/home"
          >
            <img src={logo} alt="logo" className="b-logo-icon" />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "b-nav-link-light b-nav-link-active"
                  : "b-nav-link-light"
              }
              to="/home"
            >
              HOME{" "}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "b-nav-link-light b-nav-link-active"
                  : "b-nav-link-light"
              }
              to="/services"
            >
              SERVICES{" "}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "b-nav-link-light b-nav-link-active"
                  : "b-nav-link-light"
              }
              to="/about"
            >
              ABOUT
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "b-nav-link-light b-nav-link-active"
                  : "b-nav-link-light"
              }
              to="/portfolio"
            >
              PORTFOLIO{" "}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? " b-nav-link-light b-nav-link-active"
                  : "b-nav-link-light"
              }
              to="faq"
            >
              FAQ
            </NavLink>
          </Nav>
          <ThemedButton
            onClick={props.onBookClick}
            theme="light"
            trackingId="book_navigation"
            size="md"
            text="BOOK AN APPOINTMENT"
          />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavigation;
