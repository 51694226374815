import React, { useEffect, useRef } from "react";
import Section from "../components/Section";
import ServiceCard from "../components/ServiceCard";
import ServiceCards from "../components/ServiceCards";
import microblading from "../assets/microblading.webp";
import shading from "../assets/shading.webp";
import consultation from "../assets/consultation.webp";
import Pricing from "../components/Pricing";
import AboutMe from "../components/AboutMe";
import InstaCards from "../components/InstaCards";
import InstaCard from "../components/InstaCard";
import i1 from "../assets/i1.webp";
import i1c from "../assets/i1c.webp";
import i2 from "../assets/i2.webp";
import i2b from "../assets/i2b.webp";
import i3 from "../assets/i3.webp";
import i4 from "../assets/i4.webp";
import i5 from "../assets/i5.webp";
import i6 from "../assets/i6.webp";
import i6b from "../assets/i6b.webp";
import i7 from "../assets/i7.webp";
import i8 from "../assets/i8.webp";
import i9 from "../assets/i9.webp";
import i10 from "../assets/i10.webp";

import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

interface IMainPageProps {
  onBookingClick: () => void;
}

function MainPage(props: IMainPageProps) {
  const aboutMeRef = React.useRef<HTMLDivElement>(null);
  const servicesRef = React.useRef<HTMLDivElement>(null);
  const pricingRef = React.useRef<HTMLDivElement>(null);
  const followMeRef = React.useRef<HTMLDivElement>(null);

  var location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  useEffect(() => {
    if (location.pathname == "/about") {
      aboutMeRef.current?.scrollIntoView();
    }
    if (location.pathname == "/services") {
      servicesRef.current?.scrollIntoView();
    }
    if (location.pathname == "/pricing") {
      pricingRef.current?.scrollIntoView();
    }
    if (location.pathname == "/portfolio") {
      followMeRef.current?.scrollIntoView();
    }
  }, [location]);

  return (
    <div className="App">
      <Section name="SERVICES" ref={servicesRef}>
        <ServiceCards>
          <ServiceCard
            title="FREE CONSULTATIONS"
            text="If you are looking for new eyebrow tattoo like microblading or PhiShading Combination Brows, an online consultation is a great way to discuss your goals and find a look that suits you best."
            btnText="BOOK NOW"
            imgSrc={consultation}
            btnOnClick={props.onBookingClick}
          />
          <ServiceCard
            title="MICROBLADING"
            text="Microblading is the process of implanting cosmetic pigment into the upper dermis of the skin with a manual pen and a set of very fine needles to create the appearance of natural hair. As a result, beautiful hair-like strokes give you a soft and very natural look, as well as defined eyebrows."
            btnText="BOOK NOW"
            imgSrc={microblading}
            btnOnClick={props.onBookingClick}
          />
          <ServiceCard
            title="PHISHADING COMBINATION BROWS"
            text="PhiShading is a technique that involves a combination of microblading and shading together. This technique creates more denser, fuller looking brows that appear to be powdered, but with hair strokes (microblading)."
            btnText="BOOK NOW"
            imgSrc={shading}
            btnOnClick={props.onBookingClick}
          />
        </ServiceCards>
      </Section>
      <AboutMe ref={aboutMeRef} onBookClick={props.onBookingClick}></AboutMe>
      <Section name="PRICING" ref={pricingRef}>
        <Pricing />
      </Section>
      <Section name="FOLLOW ME" ref={followMeRef}>
        <InstaCards>
          <InstaCard text="Instagram" imgSrc={i4} trackingId="i4" />
          <InstaCard
            text="Instagram"
            imgSrc={i1}
            trackingId="i1"
            altImgSrc={i1c}
          />
          <InstaCard text="Instagram" imgSrc={i5} trackingId="i5" />
          <InstaCard
            text="Instagram"
            imgSrc={i2}
            trackingId="i2"
            altImgSrc={i2b}
          />
          <InstaCard text="Instagram" imgSrc={i3} trackingId="i3" />
        </InstaCards>
        <InstaCards>
          <InstaCard text="Instagram" imgSrc={i7} trackingId="i7" />
          <InstaCard text="Instagram" imgSrc={i8} trackingId="i8" />
          <InstaCard
            text="Instagram"
            imgSrc={i6}
            trackingId="i6"
            altImgSrc={i6b}
          />
          <InstaCard text="Instagram" imgSrc={i10} trackingId="i10" />
          <InstaCard text="Instagram" imgSrc={i9} trackingId="i9" />
        </InstaCards>
      </Section>
    </div>
  );
}

export default MainPage;
