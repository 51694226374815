import React, { ReactNode } from "react";
import "./ServiceCard.scss";
import ThemedButton from "./ThemedButton";

interface IServiceCardProps {
  children?: ReactNode;
  imgSrc?: string;
  title?: string;
  text?: string;
  btnText?: string;
  btnOnClick?: () => void;
}

function ServiceCard(props: IServiceCardProps) {
  return (
    <div className="b-service-card">
      <div className="b-service-card-img-wrapper">
        <img src={props.imgSrc} alt={props.title} loading={"lazy"} />
      </div>
      <div className="b-service-card-title-wrapper">
        <h2>{props.title}</h2>
      </div>
      <div className="b-service-card-text-wrapper">
        <p>{props.text}</p>
      </div>
      <div className="b-service-card-footer">
        <div className="d-md-none text-center">
          <ThemedButton
            size="md wide-btn"
            theme="dark"
            trackingId={"book_servicecard_" + props.title}
            onClick={props.btnOnClick}
            text={props.btnText}
          />
        </div>
        <div className="d-none d-md-block">
          <ThemedButton
            size="md"
            theme="dark"
            trackingId={"book_servicecard_" + props.title}
            onClick={props.btnOnClick}
            text={props.btnText}
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
