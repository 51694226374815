import React, { ReactNode, useCallback } from "react";
import { Button } from "react-bootstrap";
import "./ThemedButton.scss";
import ReactGA from "react-ga";

interface IThemedButtonProps {
  trackingId: string;
  text?: string;
  onClick?: () => void;
  className?: string;
  size: "sm" | "md" | "md wide-btn" | "lg";
  theme: "dark" | "light" | "black" | "darker";
  children?: ReactNode;
}

function ThemedButton(props: IThemedButtonProps) {
  var className =
    props.className +
    " b-themed-button " +
    "b-themed-button-" +
    props.size +
    " b-themed-button-" +
    props.theme;

  var onClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
      ReactGA.event({ action: props.trackingId, category: "onClick" });
    }
  }, [props, props.onClick]);

  return (
    <>
      <Button onClick={onClick} className={className}>
        {props.text}
        {props.children}
      </Button>
    </>
  );
}

export default ThemedButton;
