import React from "react";
import { Modal } from "react-bootstrap";
import ThemedButton from "./ThemedButton";
import "./BookingModal.scss";
import logo_color from "../assets/logo_color.svg";
import sevid from "../assets/sevid.png";

interface IBookingModalProps {
  title: string;
  visible: boolean;
  onClose: () => void;
}

function BookingModal(props: IBookingModalProps) {
  return (
    <Modal show={props.visible} onHide={() => props.onClose()}>
      <Modal.Body className="b-booking-modal">
        <div className="b-bookin-modal-img-wrapper">
          <img src={logo_color} alt="Logo" />
        </div>
        <div className="b-booking-fee">
          <p>
            <strong>Brows and You</strong>
            &nbsp;has joined Sevid Beauty Spa in Bellevue. Book permanent
            make-up services on www.sevidbeauty.com
            <img style={{ maxWidth: "500px" }} src={sevid} />
          </p>
        </div>
        <div className="b-booking-btn">
          <a href="https://sevidbeauty.com">
            <ThemedButton
              theme="black"
              size="lg"
              trackingId="book-btn-promo-no-book"
            >
              Click here to visit sevidbeauty.com
            </ThemedButton>
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ThemedButton
          theme="dark"
          trackingId="modal_close"
          size="md"
          onClick={() => props.onClose()}
          text="CLOSE"
        />
      </Modal.Footer>
    </Modal>
  );
}

export default BookingModal;
