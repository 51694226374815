import React, { useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import "./Faq.scss";

interface IFaqDetails {
  title: string;
  description: React.ReactNode;
}

function Faq() {
  const articleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    articleRef.current?.scrollIntoView();
  }, []);

  var faqDetails: IFaqDetails[] = [
    {
      title: "What are contradictions for Eyebrow services?",
      description: (
        <>
          <p>We can't perform the service on you if :</p>
          <ul>
            <li>You are under the age of 18</li>
            <li>You are an insulin-dependent diabetic</li>
            <li>You are pregnant</li>
            <li>
              You have glaucoma or taking blood-thinning medicines (e.g.,
              Apixaban (Eliquis)Dabigatran (Pradaxa)Edoxaban
              (Savaysa)Fondaparinux (Arixtra)Heparin (Fragmin, Innohep, and
              Lovenox)Rivaroxaban (Xarelto)Warfarin (Coumadin, Jantoven))
            </li>
            <li>
              You have skin diseases such as psoriasis/ eczema/dermatitis,
              keratosis pilaris, chronic acne, sevear rosacea on treatments area
            </li>
            <li>You have hemophilia</li>
            <li>
              You have healing disorders or uncontrolled high blood pressure
            </li>
            <li>
              You are or have been on any skin medication such as Accutane or
              steroids in the past 12 months
            </li>
            <li>You have active cancer</li>
            <li>You are undergoing radiotherapy or chemotherapy</li>
            <li>You have epilepsy</li>
            <li>You have a pacemaker or major heart problems</li>
            <li>
              You had Botox in the past two weeks around the treatment area
            </li>
            <li>You are prone to keloids</li>
            <li>You have a history of fainting or seizure</li>
            <li>You are taking acutaine medication</li>
          </ul>
        </>
      ),
    },
    {
      title: "Do I need a consultation?",
      description: (
        <>
          <p>
            If you are a new client, prior to booking any services, we recommend
            having a consultation with us so we can evaluate your health &
            medical background to make sure you are the right candidate for the
            selected treatment and help you to choose the right treatment that
            is the best for you.
          </p>

          <p>
            For your convenience and to save more of your valuable time, we
            provide you an online consultation platform, which you can answer
            the questioner and submit the form so we can check and evaluate your
            request and guide you for the further steps.
          </p>

          <p>
            Also if you prefer to visit us in person, feel free to request your
            complimentary consultation from the appointments section and we will
            be happy to visit you soon.
          </p>
        </>
      ),
    },
    {
      title: "How painful are these treatments?",
      description: (
        <>
          <p>
            Pain during the treatment is relative. Scared clients usually feel
            stronger pain, while the other clients compare such pain with the
            feel when plucking out their eyebrows. In addition, with women, they
            report an increased sensitivity to pain during their menstrual
            cycle, so it is not recommended to do the treatment while ladies
            have their period.
          </p>
          <p>
            However we use diffrent types of topical anesthetics, in the form of
            cream or liquid, to completely eliminate the pain during the
            treatment.
          </p>
        </>
      ),
    },
    {
      title: "How do I know which transformation to choose?",
      description: (
        <>
          <p>
            Base on your answers to the questionnaire on the consultation
            session, and the type of your skin as well as realistic eyebrows
            expectation we will help you to choose the best treatment that suits
            your needs better.
          </p>
        </>
      ),
    },
    {
      title: "How to be prepared for my treatments?",
      description: (
        <>
          <p>
            The skin should not be burnt, nor in recovery or regeneration
            process.
          </p>
          <p>
            The following treatments are not recommended 30 days before the
            treatment:
          </p>
          <ul>
            <li>Botox and fillers on the forehead area</li>
            <li>
              Fruit or lactic acids OR use of Vitamin A/Retinol products on the
              forehead area
            </li>
            <li>Laser treatments on the forehead area</li>
            <li>Chemical peel all over the face</li>
            <li>
              Exposure to strong sunlight or tanning (pigment won't heal nicely
              on sunburned skin)
            </li>
          </ul>
          <p>
            The following treatments are not recommended
            <strong> 14 days</strong> before the treatment:
          </p>
          <ul>
            <li>
              Tweezing, waxing, shaving, threading or tinting brows two weeks
              before the procedure
            </li>
            <li>Lashes enhancement</li>
          </ul>
          <p>
            The following are not recommended
            <strong> 24 hours</strong> before the treatment:
          </p>
          <ul>
            <li>
              Coffee or Alcohol or food and drinks containing caffeine. This
              will thin your blood and increase the chance of bleeding during
              the treatment. Bleeding can cause lack of pigment retention and
              poor result.
            </li>
            <li>Taking any types of pain killers before the apointment.</li>
          </ul>
          <p>
            Wash your hair & scalp the night before the treatment (not the day
            of) and make sure you don’t apply any oil on your hair. Scalp and
            hair must be clean and free of oils or hair products. you won't be
            able to take a direct shower immediately after the treatment. taking
            a bath is okay. we recommend you to book a professional hair wash &
            styling appointment and get your hair done at a hair salon for the
            first few days to avoid getting your freshly done brows wet.
          </p>
          <p>
            <strong>
              No workout or gym before the treatment and for seven days after.
            </strong>
            &nbsp; Excess sweating will cause the pigment to fall off & scabs
            premature and affect the final result of your brow transformation.
          </p>
          <p>
            You can come to an appointment with full makeup or without. Please
            have this in mind that your pictures will be taken or we may record
            a video. You can inform the technician if you prefer not to share a
            picture of your full face.
          </p>
        </>
      ),
    },
    {
      title: "How is the healing process?",
      description: (
        <>
          <p>
            Usually, the inserted pigment is very dark during the first 5 to 7
            days then strokes or pigment lose up to 40% of their intensity. If
            you think that pigment is too dark, do not try to fade or remove it
            by yourself. Be patient and wait for 7-10 days until it fades away
            naturally.
          </p>
          <p>
            PLEASE Trust the process and if there is an important event coming
            up make sure to consider this healing process cause you cant speed
            it up or rush the healing process. you just have to let your body
            heal on its own way.
          </p>

          <p>
            On average It takes about 7-12 days for brows to heal. In that time
            any redness will fade and all dead skin and dark build-up pigments
            and scabbing will be gone gradually and the initial darkness of the
            eyebrows will begin to lighten. The color will lighten over about 21
            days as it completely sets in.
          </p>
        </>
      ),
    },
    {
      title: "What to expect after my brow transformation?",
      description: (
        <>
          <p>
            Your eyebrows will go through several phases during the healing
            cycle.
          </p>

          <ul>
            <li>
              <strong>Days 1 - 4</strong>
              <p>
                The pigment will appear very sharp and dark. This is because the
                pigment oxidizes with the oxygen in the air and simply gets
                darker, no matter how light was the initial pigment, this
                process is unavoidable. The color of the pigment will soften
                gradually. Do not be alarmed if you see some pigment on the
                cotton swab when you follow the aftercare as this is excess
                pigment and/or body fluid that is naturally exiting your skin.
              </p>
            </li>
            <li>
              <strong>Days 5 - 7</strong>
              <p>
                Once the healing of the skin starts taking place, it will look
                like dandruff flakes or dry skin. This might give you the
                impression that the pigment is fading too quickly, however, this
                is just superficial color and dry skin being naturally removed
                from your eyebrows.
              </p>
            </li>
            <li>
              <strong>Days 8 - 12</strong>
              <p>
                Your brows may look as though the pigment has completely faded –
                do not panic – take a deep breath and trust the process ;)
              </p>
            </li>
            <li>
              <strong>Days 14 - 28</strong>
              <p>
                Your brows may still look a little patchy or uneven, but the
                pigment will start to “reappear” as the pigment settles and the
                healing continues. You may get your touch-up/ perfecting
                procedure done to allow for complete healing of the brows. Touch
                up must be booked 4-10 week after the initial session. NO SOONER
                than 4 weeks or Not later than 10 weeks.
              </p>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What aftercare should I follow?",
      description: (
        <>
          {" "}
          <ul>
            <li>
              <strong>Day 1 (day of treatment)</strong>
              <p>
                30 mins After treatment, gently clean the area with a wipe (you
                will receive the wipes and the aftercare cream) Repeat this step
                5 times today every 1.5 - 2 hrs to avoid build-up of blood/lymph
                on the area.
              </p>
            </li>
            <li>
              <strong>Day 2</strong>
              <p>
                Gently clean the area with a wipe 3 times today (morning –
                afternoon – evening) to avoid build-up of blood/lymph on the
                area.
              </p>
            </li>
            <li>
              <strong>Days 3-5</strong>
              <p>Don’t do anything to your brow.</p>
            </li>
            <li>
              <strong>Days 6 - 10 or until all scabs fall off</strong>
              <p>
                Using a cotton swab, you may apply a very small amount of
                ointment to brows (very small amount-like half the size of a
                grain of rice for both brows.) This is just to add a small
                amount of moisture – brows should not appear greasy, glossy or
                shiny, if they do, you used too much.
              </p>
            </li>
          </ul>
          <p>
            Do not use aggressive movement/ manipulation of the skin- be gentle.
            Make sure your hands are clean before touching your brows. Do not
            pick scabs off browsy they are healing.
          </p>
        </>
      ),
    },
    {
      title: "How to take care of my newely done brows?",
      description: (
        <>
          <p>
            Allowing any water or moisture to touch the brows! No shower stream
            over brows. Wash face CAREFULLY with a washcloth or cleansing wipes,
            avoid brow area. if you need to wash your hair, we recommend to
            visit a hair salon for shampoo & style appointment or using dry
            shampoo to clean your hair. you can take a bath but please no water
            directly on your brows for 10 days post-treatment.
          </p>
          <p>
            <strong>Avoid for 10 days post treatment</strong>
          </p>
          <ul>
            <li>Sweating</li>
            <li>Exercising</li>
            <li>Practicing sports</li>
            <li>Swimming</li>

            <li>Hot sauna, hot bath, or Jacuzzi</li>
            <li>Sun tanning or salon tanning</li>
            <li>
              Any laser or chemical treatments or peelings, and/ or any creams
              containing Retin-A or Glycolic Acid on the face or neck
            </li>
            <li>
              Picking, peeling or scratching of the brow area. You will cause
              scarring of the area or removal of the pigment if you pick or peel
              scabs prematurely.
            </li>
            <li>
              Performing tasks related to heavy household cleaning such as
              garage or basement cleaning where there is a lot of airborne
              debris – now is not the time!
            </li>
            <li>
              Touching of the eyebrow area except for when cleaning and applying
              the post-care with a cotton swab
            </li>
            <li>
              Do not use any cleansers or facial products of any kind, over the
              brow area for at least 10 days.
            </li>
            <li>
              Absolutely do not use any ointments, antibiotic creams or gels, or
              vitamin creams over the brow area – it will draw out the pigment.
              (you should only use the cream that we provide you on the day of
              your appointment)
            </li>
          </ul>
          <p>
            Please NOTE: Itching and flaking may appear during the first seven
            days post-procedure. However, experience has shown that by following
            these after-care instructions, these symptoms may quickly disappear.
            Touch-ups and/or correction of the shape-design is recommended only
            after 4-10 weeks. Please make sure to book your complimentary touch
            up ASAP!
          </p>
        </>
      ),
    },
  ];
  return (
    <div ref={articleRef} className="row section-row justify-content-center">
      <Accordion id="b-faq-accordion" defaultActiveKey="0" flush>
        {faqDetails.map((faq, num) => {
          var evtKey = num + "";
          return (
            <Accordion.Item eventKey={evtKey}>
              <Accordion.Header>{faq.title}</Accordion.Header>
              <Accordion.Body>{faq.description}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}

export default Faq;
