import React, { useCallback, useEffect, useState } from "react";
import "./App.scss";
import MainContent from "./components/MainContent";
import TopNavigation from "./components/TopNavigation";
import BookingModal from "./components/BookingModal";
import Footer from "./components/Footer";
import MainPage from "./pages/MainPage";
import { Route, Routes, useLocation } from "react-router-dom";
import FaqPage from "./pages/FaqPage";
import CookieConsent from "react-cookie-consent";

function App() {
  const [bookingModalVisible, setBookingModalVisible] = useState(false);
  const bookingModalSetInvisible = useCallback(
    () => setBookingModalVisible(false),
    [setBookingModalVisible]
  );

  const bookingModalSetVisible = useCallback(
    () => setBookingModalVisible(true),
    [setBookingModalVisible]
  );

  const location = useLocation();

  return (
    <div className="App">
      <TopNavigation onBookClick={bookingModalSetVisible} />
      <MainContent
        altBg={location.pathname == "/faq"}
        onBookClick={bookingModalSetVisible}
      />
      <Routes>
        <Route
          path="faq"
          element={<FaqPage onBookingClick={bookingModalSetVisible} />}
        />
        <Route
          path="*"
          element={<MainPage onBookingClick={bookingModalSetVisible} />}
        />
      </Routes>
      <Footer />
      <BookingModal
        title="Book an appointment"
        visible={bookingModalVisible}
        onClose={bookingModalSetInvisible}
      />
      <CookieConsent
        style={{ background: "#000", fontSize: "1em" }}
        buttonStyle={{
          background: "rgb(183,147,110)",
          color: "#fff",
          fontSize: "1em",
          borderRadius: 5,
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
