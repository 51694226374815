import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.scss";
import logo from "../assets/logo_react.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="b-footer">
      <Container>
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 g-3 p-5">
          <div>
            <div>
              <h4>Location</h4>
              <p>
                <b>Sevid Beauty Spa</b>
                <br />
                170 Bellevue Way NE
                <br />
                98004
                <br />
                Bellevue, WA
              </p>
            </div>
            <div>
              <h4>Availability</h4>
              <p>
                Tuesday - Saturday <br /> 10 AM - 5 PM
              </p>
              <p>
                Sunday, Monday <br /> CLOSED
              </p>
            </div>
          </div>
          <div>
            <h4>Learn more</h4>
            <a href="/files/ReleaseAgreement.pdf" target="_blank">
              Consultation form
            </a>
            <a href="/files/Aftercare.pdf" target="_blank">
              Aftercare
            </a>
            <p></p>
            <Link to="/services">Services</Link>
            <Link to="/about">About me</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/portfolio">Follow me</Link>
            <Link to="/faq">FAQ</Link>
          </div>
          <div>
            <div>
              <h4>Follow me</h4>
              <a href="https://instagram.com/browsandyou.wa" target="_blank">
                @browsandyou.wa
              </a>
            </div>
            <div>
              <h4>Contact</h4>
              <a id="mail" href="mailto:info@browsandyou.net">
                info@browsandyou.net
              </a>
              <p>(425) 966-2248</p>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div>
            <img src={logo} alt="Logo" />
          </div>
          <div className="copyright">
            Copyright © 2023 Brows And You | All Rights Reserved
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
