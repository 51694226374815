import React, { useEffect, useState } from "react";
import ThemedButton from "./ThemedButton";
import "./MainContent.scss";

interface IMainContentProps {
  onBookClick: () => void;
  altBg: boolean;
}

function MainContent(props: IMainContentProps) {
  const [className, setClassName] = useState<string | undefined>(undefined);
  useEffect(() => {
    setClassName(
      "b-main-content-container" +
        (props.altBg ? " b-main-content-conainer-alt" : "")
    );
  }, []);

  return (
    <div className={className}>
      <div className="p-md-0 mx-auto my-5 jumbo-text">
        <h1 className="display-4 fw-normal">Unveil your natural beauty</h1>
        <p className="lead fw-normal">Naturally looking permanent make-up</p>
        <ThemedButton
          onClick={props.onBookClick}
          trackingId="book_main"
          size="lg"
          theme="dark"
          text="BOOK FREE CONSULTATION"
        />
      </div>
      <div className="product-device shadow-sm d-none d-md-block"></div>
      <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
    </div>
  );
}

export default MainContent;
