import React, { ReactNode } from "react";
import "./ServiceCards.scss";

interface IServiceCardsProps {
  children?: ReactNode;
}

function ServiceCards(props: IServiceCardsProps) {
  return <div className="b-service-cards">{props.children}</div>;
}

export default ServiceCards;
